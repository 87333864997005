<template>
  <main id="main-content" class="main">
    <div v-if="pageData" class="layout-default">
      <!--    todo: check title -->
      <h1 class="_visually-hidden">Homepage zuehlke.com</h1>
      <ul class="skip-links">
        <li class="item">
          <a href="/" class="link">{{ messages[locale].skipToHomepage }}</a>
        </li>
        <li class="item">
          <a href="#main-content" class="link">{{
            messages[locale].skipToMainContent
          }}</a>
        </li>
        <li class="item">
          <a href="#main-navigation" class="link">{{
            messages[locale].skipToMainNavigation
          }}</a>
        </li>
        <li class="item">
          <a href="#meta-navigation" class="link">{{
            messages[locale].skipToMetaNavigation
          }}</a>
        </li>
      </ul>
      <!--    Header Module -->
      <template v-for="p in pageData.headerParagraph">
        <component
          :is="Paragraphs[p.__typename]"
          v-if="Paragraphs[p.__typename]"
          :key="p.id"
          class="section header-paragraph"
          :data="p"
        ></component>
      </template>
      <!--   Shared Paragraphs -->
      <template v-for="p in pageData.sharedParagraphs">
        <component
          :is="Paragraphs[p.__typename]"
          v-if="Paragraphs[p.__typename]"
          :key="p.id"
          class="section shared-paragraph"
          :data="p"
        ></component>
      </template>
    </div>
  </main>
</template>

<script setup lang="ts">
import { type Locale } from '@intlify/core-base';
import type { Link, Meta } from '@unhead/schema';
import { Paragraphs } from '~/components/paragraphs/Paragraphs';

const messages: Record<Locale, Record<string, string>> = {
  en: {
    skipToHomepage: 'Skip to homepage',
    skipToMainContent: 'Skip to main content',
    skipToMainNavigation: 'Skip to main navigation',
    skipToMetaNavigation: 'Skip to meta navigation',
  },
  de: {
    skipToHomepage: 'Zur Homepage springen',
    skipToMainContent: 'Direkt zum Inhalt',
    skipToMainNavigation: 'Zur Hauptnavigation springen',
    skipToMetaNavigation: 'Zur Meta Navigation springen',
  },
};

const { locale } = useI18n();

const { data } = await useAsyncData(`node-by-id-${locale.value}`, () =>
  $fetch('/api/content/get-node-by-id', {
    query: {
      langcode: locale.value,
      id: '46599194-86b0-4a51-b95e-e2965418b4f0',
    },
  }),
);

const pageData = data.value?.data?.nodePage;

if (pageData?.metatag) {
  let title = '';
  const link: Link[] = [];
  const meta: Meta[] = [];

  pageData?.metatag.forEach((item) => {
    if (item.tag === 'meta') {
      meta.push(item.attributes);

      if (item.attributes.name === 'title' && item.attributes.content) {
        title = item.attributes.content;
      }
    } else if (item.tag === 'link') {
      link.push(item.attributes);
    }
  });

  useHead({
    htmlAttrs: {
      lang: locale.value,
      dir: 'ltr',
    },
    title,
    meta,
    link,
  });
}
</script>

<style lang="scss">
.layout-default {
  display: flex;
  flex-direction: column;

  @media (--vs) {
    gap: 4rem;
  }

  @media (--vl) {
    gap: 3rem;
  }

  //> .section {
  //  outline: 1px solid red;
  //}
}

.main {
  background-color: var(--base-color-background);
}
</style>
