<template>
  <img
    ref="imageElement"
    :alt="alt"
    src="/blurryblob.svg"
    class="media-image"
  />
</template>

<script setup lang="ts">
import type { ImageStyle, FragmentMediaImage } from '~/drupal/types';
import { useIntersectionState } from '~/utils/useIntersectionState';

type IProps = {
  data: FragmentMediaImage;
  style?: ImageStyle;
};

const props = withDefaults(defineProps<IProps>(), {
  style: 'FOCAL_POINT32',
});

const imageElement = ref<HTMLImageElement | null>(null);

const alt = computed(function () {
  return props.data.mediaImage.alt ?? '';
});

defineExpose({ imageElement });

useIntersectionState(
  imageElement,
  {
    onFirstEnter() {
      loadImage();
    },
  },
  {
    threshold: 0,
    rootMargin: '0px 0px 50% 0px',
  },
);

function loadImage() {
  if (imageElement.value && image.value) {
    imageElement.value.src = image.value.src;
    imageElement.value.srcset = image.value.srcset;
  }
}

const { data, error } = await useAsyncData(
  `get-image-by-id-${props.data.id}-${props.style}`,
  () =>
    $fetch('/api/content/get-image-by-id', {
      query: {
        // styles: [`${props.style}_MEDIUM`],
        // todo: add small styles when bug is fixed
        styles: [`${props.style}_SMALL`, `${props.style}_MEDIUM`],
        id: props.data.id,
      },
    }),
);

const imageData = computed(function () {
  if (error.value) {
    console.log(`[MEDIA IMAGE : ${props.data.id}]`, error.value);
    return null;
  }

  if (!data.value || !data.value.data || !data.value.data.mediaImage) {
    return null;
  }

  return data.value.data.mediaImage;
});

const image = computed(function () {
  if (!imageData.value || !imageData.value.image) {
    return null;
  }

  const imageSm = imageData.value.image.variations?.[0];
  const imageMd = imageData.value.image.variations?.[1];

  return {
    srcset: replaceHost(
      `${imageMd?.url} ${imageMd?.width}w, ${imageMd?.url} ${imageMd?.width}w`,
    ),
    sizes: `(max-width: 1200px) ${imageMd?.width}px, ${imageMd?.width}px`,
    src: replaceHost(imageMd?.url ?? ''),
    alt: imageData.value.image.alt ?? '',
  };

  // todo return should look like this (with imageSm at the right places)
  // todo use this when 404-error is fixed
  // return {
  //   srcset: replaceHost(
  //     `${imageSm?.url} ${imageSm?.width}w, ${imageMd?.url} ${imageMd?.width}w`,
  //   ),
  //   sizes: `(max-width: 1200px) ${imageSm?.width}px, ${imageMd?.width}px`,
  //   src: replaceHost(imageMd?.url ?? ''),
  //   alt: imageData.value.image.alt ?? '',
  // };
});

function replaceHost(str: string) {
  const config = useRuntimeConfig();

  return str.replaceAll(
    'https://nginx.dev.zuehlke.ch4.amazee.io',
    config.public.CMS_URL,
  );
}
</script>

<style scoped lang="scss">
.media-image {
}
</style>
