<template>
  <section ref="element" class="pg-teaser-big-split">
    <p v-if="data.eyebrow" class="eyebrow font-xl-bold-vl font-md-bold-vs">
      {{ data.eyebrow }}
    </p>
    <div class="imagewrapper">
      <MediaImage
        v-if="data.primaryImage"
        ref="mediaImagePrimary"
        class="image -primary"
        :style="'FOCAL_POINT11'"
        loading="lazy"
        :data="data.primaryImage"
      />
      <MediaImage
        v-if="data.secondaryImage"
        :id="data.secondaryImage.id"
        ref="mediaImageSecondary"
        class="image -secondary"
        :style="'FOCAL_POINT32'"
        loading="lazy"
        :data="data.secondaryImage"
      />
    </div>
    <h2 v-if="data.title" ref="titleElement" class="title font-2xl-bold">
      {{ data.title }}
    </h2>
    <p
      v-if="data.text"
      ref="textElement"
      class="text font-lg-medium-vl font-md-medium-vs"
    >
      {{ data.text }}
    </p>
    <LinkHandler
      v-if="data.pgCtaLink?.url"
      :path="data.pgCtaLink.url"
      class="cta"
      is-button-primary
      ><span class="label">{{ data.pgCtaLink.title }}</span>
    </LinkHandler>
  </section>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import type { Ref } from 'vue';
import type { FragmentParagraphPgTeaserBigSplit } from '~/drupal/types';
import LinkHandler from '~/components/links/LinkHandler.vue';
import { useIntersectionState } from '~/utils/useIntersectionState';
import { parseCubicBezier } from '~/utils/parseCubicBezier';

defineProps<{
  data: FragmentParagraphPgTeaserBigSplit;
}>();

const { isVl } = useViewportSize();

const titleElement = ref<HTMLElement | null>(null);
const textElement = ref<HTMLElement | null>(null);

const { play, isAnimationFinished } = fadeInAnimation();

const element = ref<null | HTMLElement>(null);

const mediaImagePrimary = ref<{
  imageElement: Ref<HTMLImageElement | null>;
}>({
  imageElement: ref(null),
});

const mediaImageSecondary = ref<{
  imageElement: Ref<HTMLImageElement | null>;
}>({
  imageElement: ref(null),
});

useIntersectionState(element, {
  onFirstEnter: play,
  onLeave() {
    isAnimationFinished.value = false;
  },
});

watchEffect(function () {
  if (
    mediaImagePrimary.value.imageElement &&
    mediaImageSecondary.value.imageElement
  ) {
    const scrollTl = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: element.value,
        scrub: 1,
      },
    });

    scrollTl
      .fromTo(
        mediaImagePrimary.value.imageElement,
        { y: isVl.value ? 100 : 50 },
        {
          y: 0,
          ease: 'circ.out',
        },
      )
      .fromTo(
        mediaImagePrimary.value.imageElement,
        { '--clip-top': '50%', '--clip-bottom': '0%' },
        {
          '--clip-top': '0%',
          '--clip-bottom': '10%',
          ease: 'circ.out',
        },
        '<',
      )
      .fromTo(
        mediaImageSecondary.value.imageElement,
        { '--clip-top': '35%', '--clip-bottom': '0%' },
        { '--clip-top': '0%', '--clip-bottom': '0%', ease: 'circ.out' },
        '<',
      );
  }
});

onMounted(async function () {});

function fadeInAnimation() {
  const ease = parseCubicBezier('cubic-bezier(0.75,0,.25,1)');
  const isAnimationFinished = ref<boolean>(false);
  const preferredMotion = usePreferredReducedMotion();

  const tl = gsap.timeline({
    paused: true,
    onComplete: function () {
      isAnimationFinished.value = true;
    },
  });

  onMounted(function () {
    if (preferredMotion.value === 'reduce') {
      return;
    }

    const split = new SplitText(titleElement.value, {
      type: 'lines,words',
      linesClass: 'line',
      wordsClass: 'word',
    });

    tl.fromTo(split.lines, { '--y': '100%' }, { '--y': 0, stagger: 0.1, ease })
      .fromTo(textElement.value, { y: 150 }, { y: 0, duration: 0.5, ease }, '<')
      .fromTo(
        textElement.value,
        { opacity: 0 },
        { opacity: 1, duration: 1, ease: 'circ.out' },
        '<25%',
      )
      .fromTo(
        '.pg-teaser-big-split .cta',
        { opacity: 0, rotate: 10 },
        { opacity: 1, rotate: 0, duration: 0.6, ease },
        '<',
      );
  });

  return {
    tl,
    play,
    isAnimationFinished,
  };

  function play() {
    tl.play(0);
  }
}
</script>

<style scoped lang="scss">
.pg-teaser-big-split {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: start;
  padding: var(--base-component-padding);

  > .eyebrow {
    grid-column: 1 / 3;

    @media (--vs) {
    }

    @media (--vl) {
    }
  }

  > .imagewrapper {
    grid-column: 1 / 3;
    display: grid;

    @media (--vs) {
      grid-template-columns: 55% 45%;
      margin: 0 calc(-1 * var(--base-component-padding-inline)); /* place images at both edges without padding of container */
    }

    @media (--vl) {
      grid-template-columns: 45% 55%;
      margin-right: calc(
        -1 * var(--base-component-padding-inline)
      ); /* place image at the right edge without padding of container */
    }

    > .image {
      --clip-top: 20%;
      --clip-bottom: 20%;

      clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);

      &.-primary {
      }
      &.-secondary {
      }

      &:first-of-type {
        @media (--vs) {
          padding: 1.5rem 0 1.5rem;
        }

        @media (--vl) {
          padding: 0 0 4.5rem;
        }
      }
    }
  }

  > .title {
    width: 80%;

    @media (--vs) {
      grid-column: 1 / 3;
      padding-bottom: 1.5rem;
    }

    @media (--vl) {
      text-wrap: wrap;
    }

    :deep(.line) {
      overflow-y: clip;

      > .word {
        transform: translate(0, var(--y));
      }
    }
  }

  > .text {
    text-wrap: pretty;

    @media (--vs) {
      padding-bottom: 1.5rem;
      grid-column: 1 / 3;
    }

    @media (--vl) {
      padding-bottom: 3rem;
      max-width: 44ch;
    }
  }

  > .cta {
    grid-column: 2 / 3;
    transform-origin: left center;

    @media (--vs) {
      justify-self: end;
    }

    @media (--vl) {
      margin-bottom: 5rem;
    }
  }
}
</style>
