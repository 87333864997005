<template>
  <svg
    class="svg-hatching"
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="207"
    viewBox="0 0 50 207"
    fill="none"
  >
    <g :clip-path="`url(#${id})`">
      <path
        d="M-125.6 132.35L66.05 -59.3"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-119.95 138L71.7 -53.65"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-114.3 143.65L77.35 -48"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-108.65 149.3L83 -42.35"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-103 154.95L88.65 -36.75"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-97.35 160.6L94.3 -31.1"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-91.75 166.2L99.95 -25.45"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-86.1 171.85L105.6 -19.8"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-80.45 177.5L111.2 -14.15"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-74.8 183.15L116.85 -8.5"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-69.15 188.8L122.5 -2.85"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-63.5 194.45L128.15 2.8"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-57.85 200.1L133.8 8.4"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-52.2 205.75L139.45 14.05"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-46.6 211.35L145.1 19.7"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-40.95 217L150.75 25.35"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-35.3 222.65L156.35 31"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-29.65 228.3L162 36.65"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-24 233.95L167.65 42.3"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-18.35 239.6L173.3 47.95"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-12.7 245.25L178.95 53.6"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-7.05005 250.9L184.6 59.2"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M-1.40002 256.5L190.25 64.85"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M4.19995 262.15L195.9 70.5"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M9.84998 267.8L201.5 76.15"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M15.5 273.45L207.15 81.8"
        stroke-width="3"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath :id="id">
        <rect width="50" height="207" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
const id = 'clip_' + Math.random().toString(16).slice(2);
</script>

<style scoped lang="scss">
.svg-hatching {
  user-select: none;
  pointer-events: none;

  path {
    stroke: var(--color-topaz-050);
  }
}
</style>
