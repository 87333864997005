<template>
  <article
    ref="headerHeroElement"
    class="header-hero-title-text"
    :class="{ '-modal-open': isModalOpen }"
  >
    <div
      ref="innerElement"
      class="inner"
      :class="{ '-modal-open': isModalOpen }"
      :style="{ '--top': scrollY }"
    >
      <div ref="titleWrapperElement" class="titlewrapper">
        <h2 ref="titleElement" class="title font-display-3xl-zuehlke">
          {{ data.title }}
        </h2>
      </div>
      <div ref="leadWrapperElement" class="leadwrapper">
        <p v-if="data.lead" class="lead font-lg-medium-vl font-md-medium-vs">
          {{ data.lead }}
        </p>
        <button class="button button-tertiary" @click="toggleModal(true)">
          Quick Look
        </button>
      </div>
      <div
        ref="dialogWrapperElement"
        :class="{ '-open': isModalOpen }"
        class="dialogwrapper hero-dialog-wrapper"
      >
        <div ref="dialogElement" class="modal" data-open="false">
          <p
            ref="dialogTitleElement"
            class="title font-xl-bold-vl font-lg-bold-vs"
          >
            {{ data.title }}
          </p>
          <p
            ref="dialogTextElement"
            class="text font-xl-medium-vl font-md-medium-vs"
          >
            {{ data.text }}
          </p>
          <!--todo@alice: add correct link path-->
          <LinkHandler
            v-if="data.pgCtaLink"
            class="button-tertiary dialog-link"
            :path="data.pgCtaLink.url"
            ><span class="label">{{ data.pgCtaLink.title }}</span>
          </LinkHandler>
        </div>
        <button
          ref="dialogButtonElement"
          type="button"
          class="closebutton button-arrow-down"
          @click="toggleModal(false)"
        >
          <span class="arrow"></span>
          <span class="text">Close</span>
        </button>
      </div>
      <figure
        ref="figureRightElement"
        data-cursor="quicklook"
        class="figure -right"
        @click="toggleModal(true)"
      >
        <div class="imagewrapper -top">
          <MediaImage
            v-if="topImage"
            :data="topImage"
            :style="'FOCAL_POINT11'"
            class="image -top"
          />
        </div>
        <div class="imagewrapper -bottom">
          <MediaImage
            v-if="bottomImage"
            :data="bottomImage"
            :style="'FOCAL_POINT21'"
            class="image -bottom"
          />
        </div>
      </figure>
      <div ref="ctaElement" class="cta"></div>
    </div>
  </article>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import type { FragmentParagraphPgHeaderHero } from '~/drupal/types';
import LinkHandler from '~/components/links/LinkHandler.vue';
import { useHeroImages } from '~/components/paragraphs/header-hero/useHeroImages';
import { useModalToggle } from '~/components/paragraphs/header-hero/useModalToggle';
import { useIntersectionState } from '~/utils/useIntersectionState';
import { parseCubicBezier } from '~/utils/parseCubicBezier';
import { useImageParallax } from '~/components/paragraphs/header-hero/useImageParallax';

const props = defineProps<{
  data: FragmentParagraphPgHeaderHero;
}>();

const { primaryImage: topImage, secondaryImage: bottomImage } = useHeroImages(
  props.data,
);

const headerHeroElement = ref<HTMLElement | null>(null);
const innerElement = ref<HTMLElement | null>(null);
const dialogElement = ref<HTMLDialogElement | null>(null);
const dialogWrapperElement = ref<HTMLElement | null>(null);
const dialogTitleElement = ref<HTMLElement | null>(null);
const dialogTextElement = ref<HTMLElement | null>(null);
const dialogButtonElement = ref<HTMLElement | null>(null);
const titleElement = ref<HTMLElement | null>(null);
const titleWrapperElement = ref<HTMLElement | null>(null);
const leadWrapperElement = ref<HTMLElement | null>(null);
const figureRightElement = ref<HTMLElement | null>(null);
const ctaElement = ref<HTMLElement | null>(null);

const { isVs } = useViewportSize();
const { y: scrollY } = useWindowScroll();
const preferredMotion = usePreferredReducedMotion();

const { tlModal } = animations();

useImageParallax(headerHeroElement);

const { toggleModal, isModalOpen } = useModalToggle(dialogElement, {
  onOpen: function () {
    if (preferredMotion.value === 'reduce') {
      tlModal.progress(1);
    } else {
      tlModal.timeScale(1).play(0);
    }
  },
  onClose: function () {
    if (preferredMotion.value === 'reduce') {
      tlModal.progress(0);
    } else {
      tlModal.timeScale(1.5).reverse();
    }
  },
});

function animations() {
  const ease = parseCubicBezier('cubic-bezier(0.75,0,.25,1)');
  const titleSplit = ref<SplitText | null>(null);

  const tlModal = gsap.timeline({
    paused: true,
  });

  const tlFadeIn = gsap.timeline({
    paused: true,
    onComplete: setModalAnimation,
  });

  useIntersectionState(
    innerElement,
    {
      onFirstEnter: () => tlFadeIn.play(0),
    },
    {
      threshold: 0,
    },
  );

  onMounted(function () {
    titleSplit.value = new SplitText(titleElement.value, {
      type: 'lines,words',
      linesClass: 'line',
      wordsClass: 'word',
    });

    setFadeInAnimation();
  });

  return {
    tlModal,
    tlFadeIn,
  };

  function setFadeInAnimation() {
    const duration = 1;

    tlFadeIn
      .fromTo(
        titleSplit.value?.lines ?? [],
        {
          '--y': '-110%',
        },
        {
          '--y': '0%',
          ease,
          stagger: 0.1,
          duration,
        },
      )
      .fromTo(
        leadWrapperElement.value,
        {
          y: -150,
        },
        {
          y: 0,
          ease,
          duration,
        },
        '<',
      )
      .fromTo(
        leadWrapperElement.value,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: 'none',
          delay: 0.2,
          duration: 1,
        },
        '-=0.9',
      );
    // .fromTo(
    //   figureRightElement.value,
    //   {
    //     clipPath: 'inset(100% 0% 0% 0%)',
    //   },
    //   {
    //     clipPath: 'inset(0% 0% 0% 0%)',
    //     ease: 'power4.out',
    //     duration,
    //   },
    //   '<',
    // );
  }

  function setModalAnimation() {
    if (isVs.value) {
      setModalAnimationVs();
    } else {
      setModalAnimationVl();
    }
  }

  function setModalAnimationVs() {}

  function setModalAnimationVl() {
    const duration = 2;
    const toTop =
      (titleWrapperElement.value?.clientHeight ?? 0) +
      (leadWrapperElement.value?.clientHeight ?? 0);

    tlModal
      .fromTo(
        titleSplit.value?.words ?? [],
        {
          y: '0%',
        },
        {
          y: '-110%',
          ease,
          duration,
        },
      )
      .fromTo(
        [figureRightElement.value],
        {
          y: 0,
        },
        {
          y: (ctaElement.value?.clientHeight ?? 0) * -1,
          ease,
          duration,
        },
        '<',
      )
      .fromTo(
        dialogWrapperElement.value,
        {
          y: window.innerHeight * -1,
        },
        {
          y: toTop * -1,
          ease,
          duration,
        },
        '<',
      )
      .fromTo(
        ctaElement.value,
        {
          y: 0,
        },
        {
          y: '-100%',
          ease,
          duration,
        },
        '<',
      )
      .fromTo(
        ctaElement.value,
        {
          'clip-path': 'inset(100% 0 0% 0)',
        },
        {
          'clip-path': 'inset(0% 0 0% 0)',
          ease,
          duration,
        },
        '<',
      )
      .fromTo(
        [
          dialogTextElement.value,
          dialogTitleElement.value,
          '.header-hero-title-text .dialog-link',
        ],
        {
          opacity: 0,
          y: -200,
        },
        {
          opacity: 1,
          y: 0,
          ease,
          duration,
        },
        '<',
      )
      .fromTo(
        dialogButtonElement.value,
        {
          y: 200,
        },
        {
          y: 0,
          ease,
          duration,
        },
        '<',
      );
  }
}
</script>

<style scoped lang="scss">
.header-hero-title-text {
  margin: 2rem 0;

  > .inner {
    display: grid;
    grid-template-columns: 878fr 622fr;

    &.-modal-open {
      position: fixed;
      z-index: 110;
      inset: var(--top) 0 0;
    }

    > .titlewrapper {
      grid-area: title;

      > .title {
        hyphens: auto;
        line-break: auto;
      }
    }

    > .leadwrapper {
      grid-area: lead;
    }

    > .figure {
      grid-area: image-right;
      cursor: pointer;

      > .imagewrapper {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: clip;

        &.-top {
          height: 40%;
        }

        &.-bottom {
          height: 60%;
        }

        > .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    > .cta {
      grid-area: cta;
      background-color: #241937;
      color: var(--color-white);
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--height-row1);
    }

    > .dialogwrapper {
      &:not(.-open) {
        pointer-events: none;
      }
    }
  }
}

//style vs
.header-hero-title-text {
  @media (--vs) {
    padding-bottom: 4rem;

    > .inner {
      grid-template-areas:
        'title title'
        'lead lead'
        'image-right image-right'
        'cta cta';

      > .titlewrapper {
        > .title {
          padding: 0 var(--base-component-padding-inline) 1.5rem
            var(--base-component-padding-inline);
        }
      }

      > .leadwrapper {
        padding: 0 var(--base-component-padding-inline);
        padding-bottom: 2.5rem;

        > .lead {
          padding-bottom: 1.5rem;
        }
      }

      > .figure {
        height: fit-content;
        aspect-ratio: 4 / 2.8;
        width: 90%;
        margin-right: 0;
        justify-self: flex-end;

        > .image {
          &.-top {
            height: 66%;
          }

          &.-bottom {
            height: 33%;
          }
        }
      }
    }
  }
}

//style vl
.header-hero-title-text {
  @media (--vl) {
    --height-row1: 30rem;
    --height-row2: calc(100lvh - 30rem);
    --height-total: 100lvh;

    height: var(--height-total);

    > .inner {
      grid-template-areas:
        'title image-right'
        'lead image-right'
        'emtpy2 cta';
      grid-template-rows: var(--height-row1) var(--height-row2) 0;
      min-height: var(--height-total);
      overflow-y: clip;

      &.-modal-open {
        > .figure {
          pointer-events: none;
        }
      }

      > .titlewrapper {
        padding: 4rem var(--base-component-padding-inline);
        display: flex;

        > .title {
          width: 100%;
          height: fit-content;
          margin-top: auto;
          line-height: 120%;

          :deep(.line) {
            overflow-y: clip;

            > .word {
              transform: translateY(var(--y));
            }
          }
        }
      }

      > .leadwrapper {
        padding: 0 var(--base-component-padding-inline) 1.5rem
          var(--base-component-padding-inline);

        > .lead {
          padding-bottom: 1.5rem;
        }
      }
    }
  }
}
</style>
text
