<template>
  <div class="teaser-carousel-item">
    <div class="content">
      <p
        v-if="data.eyebrow"
        ref="eyebrowElement"
        class="eyebrow font-caps-xs-bold"
      >
        {{ data.eyebrow }}
      </p>
      <h3 v-if="data.title" ref="titleElement" class="title font-2xl-bold">
        {{ data.title }}
      </h3>
      <p v-if="data.lead" class="text font-lg-medium-vl font-md-medium-vs">
        {{ data.lead }}
      </p>
      <LinkHandler
        v-if="data.pgCtaLink"
        :path="data.pgCtaLink.url"
        class="cta button-tertiary"
        ><span class="label">{{ data.pgCtaLink.title }}</span>
      </LinkHandler>
    </div>
    <div class="figure" data-cursor="dive-deeper">
      <LinkHandler
        v-if="data.pgCtaLink?.url"
        class="inner"
        :path="data.pgCtaLink.url"
      >
        <MediaImage
          :data="data.primaryImage"
          class="mediaimage"
          loading="lazy"
          :style="'FOCAL_POINT43'"
        />
      </LinkHandler>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import type { FragmentParagraphPgTeaserCarouselItem } from '~/drupal/types';
import LinkHandler from '~/components/links/LinkHandler.vue';

defineProps<{
  data: FragmentParagraphPgTeaserCarouselItem;
  index: number;
}>();

const titleElement = ref<HTMLElement | null>(null);

onMounted(function () {
  const split = new SplitText(titleElement.value, {
    type: 'lines,words',
    linesClass: 'line',
    wordsClass: 'word',
  });

  for (let i = 0; i < split.lines.length; i++) {
    const line = split.lines[i];

    gsap.set(line, {
      transitionDelay: 0,
      '--stagger': `${60 * i}ms`,
    });
  }
});
</script>

<style scoped lang="scss">
.teaser-carousel-item {
  // total-duration = in-duration + out-duration
  --slide-transition-duration: 2000ms;
  --slide-tranition-in-duration: 1000ms;
  --slide-tranition-out-duration: 1000ms;

  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, auto));
  position: relative;
  height: 100%;

  &.-active,
  &.-active-left,
  &.-active-right {
    > .figure {
      visibility: visible;
    }
  }

  &.-active {
    > .content {
      opacity: 1;
      transition-delay: var(--slide-tranition-in-duration) var(--ease);
      z-index: 10;

      > .title > :deep(.line) > .word {
        transform: translate(0, 0);
        transition-delay: calc(
          var(--stagger) + var(--slide-tranition-in-duration)
        );
      }

      > .text {
        opacity: 1;
        transform: translate(0, 0);
        transition-delay: var(--slide-tranition-in-duration);
      }

      > .eyebrow,
      > .cta {
        opacity: 1;
        transition-delay: 2000ms;
      }
    }

    > .figure {
      visibility: visible;

      > .inner {
        transform: translate(0, 0);

        .mediaimage {
          scale: 1 1;
        }
      }
    }
  }

  &.-left,
  &.-active-left {
    pointer-events: none;

    > .content {
      opacity: 0;

      > .title > :deep(.line) > .word {
        transform: translate(0, 3.5rem);
      }

      > .text {
        transform: translate(0, 3.5rem);
        opacity: 0;
      }

      > .eyebrow,
      > .cta {
        opacity: 0;
      }
    }

    > .figure {
      > .inner {
        transform: translate(-40vw, 0);

        .mediaimage {
          scale: 2 1;
        }
      }
    }
  }

  &.-right,
  &.-active-right-next,
  &.-active-right {
    pointer-events: none;

    > .content {
      opacity: 0;

      > .title > :deep(.line) > .word {
        transform: translate(0, -3.5rem);
      }

      > .text {
        transform: translate(0, -3.5rem);
        opacity: 0;
      }

      > .eyebrow,
      > .cta {
        opacity: 0;
      }
    }

    > .figure {
      > .inner {
        .mediaimage {
          scale: 2 1;
        }
      }
    }
  }

  &.-right,
  &.-active-right-next {
    > .figure > .inner {
      transform: translate(80vw, 0);
    }
  }

  &.-active-right {
    > .figure > .inner {
      transform: translate(40vw, 0);
    }
  }

  &.-previous {
    > .content {
      transition-delay: var(--slide-tranition-out-duration);
    }
  }

  > .content {
    z-index: 10;
    padding: 0 var(--base-component-padding-inline);
    height: var(--height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    transition: opacity 500ms;
    position: relative;

    > .eyebrow,
    > .cta {
      transition: opacity 500ms;
    }

    > .title > :deep(.line) > .word,
    > .title,
    > .text {
      transition:
        transform 700ms var(--ease-out-sine),
        opacity 700ms var(--ease-out-sine);
    }

    > .title {
      padding-bottom: 2rem;

      > :deep(.line) {
        overflow-y: clip;
        margin: 0.25em 0;

        > .word {
          transition-delay: inherit;
        }
      }
    }

    > .text {
      padding-bottom: 3.5rem;
    }
  }

  > .figure {
    --width: 40vw;
    --width-negative: -40vw;

    height: var(--height);
    width: 100%;
    visibility: hidden;
    cursor: pointer;

    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      inset: 0 -100% 0 auto;
      width: 100%;
      height: 100%;
      border: 10rem solid var(--base-color-background);
      transition: border var(--base-transition-duration-slow) var(--ease);
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0 auto 0 0;
      width: var(--width);
      height: var(--height);
      background-color: var(--base-color-background);
      transition: background-color var(--base-transition-duration-slow)
        var(--ease);
    }

    > .inner {
      height: 100%;
      width: var(--width);
      transition: transform var(--slide-transition-duration) var(--ease);
      overflow: hidden;
      display: block;
      text-decoration: none;
      position: relative;

      .mediaimage {
        height: 100%;
        width: 100%;
        transition: scale var(--slide-transition-duration) var(--ease);
        object-fit: cover;
      }
    }
  }
}
</style>
